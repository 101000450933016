import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {

  function sendEmail() {
    try{
      // axios.post('https://api.innoestate.fr/emails/add', {email: document.querySelector('#emailInput').value})
      axios.get(`https://api.innoestate.fr/emails/add?email=${document.querySelector('#newsletterInput3-1').value}&app=offerai`).then((res) => {
        alert('merci!');
      });
    }catch(e){
      console.error(e);
      alert(`une erreur s'est produite!`);

    }
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section
          id='home'
          className='position-relative bg-white overflow-hidden'
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          <div
            className='position-absolute top-0 start-0 h-100 w-100'
            style={{
              background:
                'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
            }}
          />
          <div className='position-relative'>
            <nav className='navbar navbar-dark navbar-expand-lg py-5 py-lg-1'>
              <div className='container-fluid'>
                <a className='navbar-brand' href='#'>
                  <img
                    className='img-fluid'
                    src='flex-assets/logos/flex-dark-green.svg'
                    alt=''
                  />
                </a>
                <div className='collapse navbar-collapse justify-content-center'>
                  <ul className='navbar-nav'>
                    <li className='nav-item p-6'>
                      <a className='nav-link link-secondary p-0' href='#home'>
                        accueil
                      </a>
                    </li>
                    <li className='nav-item p-6'>
                      <a
                        className='nav-link link-secondary p-0'
                        href='#how-it-works'
                      >
                        comment ça marche
                      </a>
                    </li>
                    <li className='nav-item p-6'>
                      <a
                        className='nav-link link-secondary p-0'
                        href='#details'
                        contentEditable='false'
                      >
                        détails
                      </a>
                    </li>
                    <li className='nav-item p-6'>
                      <a
                        className='nav-link link-secondary p-0'
                        href='/policies'
                        contentEditable='false'
                      >
                        politique de condifentialité
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='d-none d-lg-block'>
                  <div className='row align-items-center g-6'>
                    <div className='col-auto' />
                    <div className='col-auto' />
                  </div>
                </div>
                <div className='d-lg-none'>
                  <a className='btn navbar-burger p-0' href='#'>
                    <svg
                      width={35}
                      height={35}
                      viewBox='0 0 32 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        className='text-light-light'
                        width={32}
                        height={32}
                        rx={6}
                        fill='currentColor'
                      />
                      <path
                        className='text-secondary'
                        d='M7 12H25C25.2652 12 25.5196 11.8946 25.7071 11.7071C25.8946 11.5196 26 11.2652 26 11C26 10.7348 25.8946 10.4804 25.7071 10.2929C25.5196 10.1054 25.2652 10 25 10H7C6.73478 10 6.48043 10.1054 6.29289 10.2929C6.10536 10.4804 6 10.7348 6 11C6 11.2652 6.10536 11.5196 6.29289 11.7071C6.48043 11.8946 6.73478 12 7 12ZM25 15H7C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16C6 16.2652 6.10536 16.5196 6.29289 16.7071C6.48043 16.8946 6.73478 17 7 17H25C25.2652 17 25.5196 16.8946 25.7071 16.7071C25.8946 16.5196 26 16.2652 26 16C26 15.7348 25.8946 15.4804 25.7071 15.2929C25.5196 15.1054 25.2652 15 25 15ZM25 20H7C6.73478 20 6.48043 20.1054 6.29289 20.2929C6.10536 20.4804 6 20.7348 6 21C6 21.2652 6.10536 21.5196 6.29289 21.7071C6.48043 21.8946 6.73478 22 7 22H25C25.2652 22 25.5196 21.8946 25.7071 21.7071C25.8946 21.5196 26 21.2652 26 21C26 20.7348 25.8946 20.4804 25.7071 20.2929C25.5196 20.1054 25.2652 20 25 20Z'
                        fill='currentColor'
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </nav>
            <div
              className='d-none navbar-menu position-fixed top-0 start-0 bottom-0 w-75 mw-xs'
              style={{ zIndex: 9999 }}
            >
              <div
                className='navbar-backdrop position-fixed top-0 start-0 end-0 bottom-0 bg-dark'
                style={{ opacity: '75%' }}
              />
              <nav className='position-relative h-100 w-100 d-flex flex-column justify-content-between p-6 bg-white overflow-auto'>
                <div className='d-flex align-items-center'>
                  <a className='me-auto h4 mb-0 text-decoration-none' href='#'>
                    <img
                      className='img-fluid'
                      src='flex-assets/logos/flex-dark-green.svg'
                      alt=''
                    />
                  </a>
                  <a className='navbar-close text-secondary' href='#'>
                    <svg
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6 18L18 6M6 6L18 18'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </a>
                </div>
                <div className='py-16'>
                  <ul className='nav nav-pills flex-column'>
                    <li className='nav-item'>
                      <a
                        className='nav-link link-secondary-dark bg-light-light'
                        href='#'
                      >
                        Product
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link link-secondary' href='#'>
                        Features
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link link-secondary' href='#'>
                        Pricing
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link link-secondary' href='#'>
                        Resources
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='row align-items-center g-6'>
                  <div className='col-12'>
                    <a
                      className='link-secondary fs-11 d-block w-100 text-center fw-medium'
                      href='#'
                    >
                      Log In
                    </a>
                  </div>
                  <div className='col-12'>
                    <a
                      className='btn btn-sm btn-success w-100 text-success-light shadow'
                      href='#'
                    >
                      Sign Up
                    </a>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <div className='container'>
            <div className='pt-28 pb-28 position-relative'>
              <div className='row g-16'>
                <div className='col-12 col-md-6'>
                  <div className='mw-md-lg'>
                    <span className='mb-4 badge bg-success text-white text-uppercase shadow'>
                      OFFERAI
                    </span>
                    <h1
                      className='mb-6 font-heading fs-4'
                      style={{ letterSpacing: '-1px' }}
                    >
                      Vos offres d'achat sur un plateau!
                    </h1>
                    <p className='mb-8 fs-9 fw-medium text-secondary'>
                      Boostez votre prospection immobilière en générant des
                      offres de qualité en quelques instants.
                    </p>
                    <div className='row g-4'>
                      <div className='col-12 col-md-auto'>
                        <a
                          className='btn btn-lg btn-success w-100 text-success-light shadow'
                          href='#'
                        >
                          ESSAYER GRATUITEMENT
                        </a>
                      </div>
                      <div className='col-12 col-md-auto'>
                        <div className='bg-white rounded' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='row justify-content-center'>
                    <div className='position-relative col-auto'>
                      <img
                        className='position-absolute top-0 start-0 mt-n12 ms-n12'
                        style={{ zIndex: 1 }}
                        src='flex-assets/elements/circle-orange.svg'
                        alt=''
                      />
                      <img
                        className='position-absolute bottom-0 end-0 me-n5 mb-n5'
                        style={{ zIndex: 1 }}
                        src='flex-assets/elements/dots-blue.svg'
                        alt=''
                      />
                      <img
                        className='position-relative img-fluid'
                        src='images/real-estate-g38a89bd2c-1280.jpg'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id='howItWorks' />
        <section
          id='how-it-works'
          className='position-relative py-20 bg-secondary-dark overflow-hidden'
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-dark.png")',
          }}
        >
          <div
            className='position-absolute top-0 start-0 h-100 w-100'
            style={{
              background:
                'radial-gradient(50% 50% at 50% 50%, rgba(42, 51, 66, 0) 0%, #2A3342 100%)',
            }}
          />
          <div className='position-relative container' style={{ zIndex: 1 }}>
            <div className='row align-items-xl-center g-16'>
              <div className='col-12 col-md-6'>
                <div className='row justify-content-center justify-content-md-start'>
                  <div className='col-auto'>
                    <div className='position-relative'>
                      <img
                        className='w-100'
                        src='images/Capture-d-ecran-2023-08-08-a-19-08-37.png'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='mw-md-xl'>
                  <h2
                    className='font-heading mb-12 fs-5 text-white'
                    style={{ letterSpacing: '-1px' }}
                  >
                    Comment ça marche
                  </h2>
                  <div className='row g-8'>
                    <div className='col-12 col-md-6'>
                      <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{ width: 48, height: 48 }}
                      >
                        1
                      </div>
                      <h3 className='mb-2 font-heading fs-9 text-white'>
                        installez l'application sur votre navigateur
                      </h3>
                      <p className='mb-0 text-secondary-light fw-medium'>
                        Installez l'application sur le store chrome. Une fois
                        installée paramétrez vos informations (nom, prénom
                        adresse etc...) personnelles ou professionnelles.
                      </p>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{ width: 48, height: 48 }}
                      >
                        2
                      </div>
                      <h3 className='mb-2 font-heading fs-9 text-white'>
                        ciblez les annonces
                      </h3>
                      <p className='mb-0 text-secondary-light fw-medium'>
                        Ouvrez un onglet sur une annonce immobilière pour
                        laquelle vous voulez faire une offre. Veillez a ce que
                        les descriptions soient bien visible sur la page.
                      </p>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{ width: 48, height: 48 }}
                      >
                        3
                      </div>
                      <h3 className='mb-2 font-heading fs-9 text-white'>
                        paramétrez
                      </h3>
                      <p className='mb-0 text-secondary-light fw-medium'>
                        Indiquez le prix que vous voulez négocier, selectionnez
                        les options et ajoutez des notes comme par exemple une
                        visite avec des constats divers sur les travaux à
                        réaliser. (ici seul le prix a été choisi comme option)
                      </p>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div
                        className='mb-4 d-flex justify-content-center align-items-center fs-9 text-secondary-dark fw-semibold bg-success rounded-pill'
                        style={{ width: 48, height: 48 }}
                      >
                        4
                      </div>
                      <h3 className='mb-2 font-heading fs-9 text-white'>
                        générez votre offre
                      </h3>
                      <p className='mb-0 text-secondary-light fw-medium'>
                        Cliquez sur le boutongénérer et patientez quelques
                        instants. C'est fait vous avez une offre prête à
                        l'emploie en PDF téléchargée dirrectement sur votre
                        ordinateur.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id='details'
          className='position-relative pt-24 pb-32 bg-white overflow-hidden'
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          <div
            className='position-absolute top-0 start-0 h-100 w-100'
            style={{
              background:
                'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
            }}
          />
          <div className='position-relative container' style={{ zIndex: 1 }}>
            <div className='mb-20 mw-4xl'>
              <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                Features
              </span>
              <h2
                className='font-heading mb-4 fs-6'
                style={{ letterSpacing: '-1px' }}
              >
                Vous aimez faire des "coups de fusils"
              </h2>
              <p className='mb-0 fs-9 fw-medium text-secondary'>
                Passez a la mitrailleuse lourdre
              </p>
            </div>
            <div className='row align-items-center g-16'>
              <div className='col-12 col-md-6'>
                <div className='row g-10'>
                  <div className='col-12'>
                    <div className='mw-md'>
                      <div className='row g-6'>
                        <div className='col-auto'>
                          <div
                            className='mb-6 d-flex justify-content-center align-items-center mx-auto text-white bg-success rounded'
                            style={{ width: 56, height: 56 }}
                          >
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_631_12694)'>
                                <path
                                  d='M17 21.63H5C4.20435 21.63 3.44129 21.3139 2.87868 20.7513C2.31607 20.1887 2 19.4257 2 18.63V10.63C2 10.3648 1.89464 10.1104 1.70711 9.9229C1.51957 9.73536 1.26522 9.63 1 9.63C0.734784 9.63 0.48043 9.73536 0.292893 9.9229C0.105357 10.1104 0 10.3648 0 10.63L0 18.63C0 19.9561 0.526784 21.2279 1.46447 22.1655C2.40215 23.1032 3.67392 23.63 5 23.63H17C17.2652 23.63 17.5196 23.5246 17.7071 23.3371C17.8946 23.1496 18 22.8952 18 22.63C18 22.3648 17.8946 22.1104 17.7071 21.9229C17.5196 21.7354 17.2652 21.63 17 21.63ZM21 3.63H7C6.20435 3.63 5.44129 3.94608 4.87868 4.50868C4.31607 5.07129 4 5.83436 4 6.63V16.63C4 17.4257 4.31607 18.1887 4.87868 18.7513C5.44129 19.3139 6.20435 19.63 7 19.63H21C21.7956 19.63 22.5587 19.3139 23.1213 18.7513C23.6839 18.1887 24 17.4257 24 16.63V6.63C24 5.83436 23.6839 5.07129 23.1213 4.50868C22.5587 3.94608 21.7956 3.63 21 3.63V3.63ZM20.59 5.63L14.71 11.51C14.617 11.6037 14.5064 11.6781 14.3846 11.7289C14.2627 11.7797 14.132 11.8058 14 11.8058C13.868 11.8058 13.7373 11.7797 13.6154 11.7289C13.4936 11.6781 13.383 11.6037 13.29 11.51L7.41 5.63H20.59ZM22 16.63C22 16.8952 21.8946 17.1496 21.7071 17.3371C21.5196 17.5246 21.2652 17.63 21 17.63H7C6.73478 17.63 6.48043 17.5246 6.29289 17.3371C6.10536 17.1496 6 16.8952 6 16.63V7L11.88 12.88C12.4425 13.4418 13.205 13.7574 14 13.7574C14.795 13.7574 15.5575 13.4418 16.12 12.88L22 7V16.63Z'
                                  fill='currentColor'
                                />
                              </g>
                              <defs>
                                <clipPath id='clip0_631_12694'>
                                  <rect
                                    width={24}
                                    height={24}
                                    fill='currentColor'
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                        <div className='col'>
                          <h3 className='font-heading fs-8 mb-4 mt-3'>
                            La chance ça se provoque
                          </h3>
                          <p className='mb-0 fw-medium text-secondary'>
                            Une offre aggressive a une chance sur 100 de passer.
                            Faire des offres à la chaine est le seul moyen
                            statistiquement d'obtenir une affaire
                            exceptionnelle!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mw-md'>
                      <div className='row g-6'>
                        <div className='col-auto'>
                          <div
                            className='mb-6 d-flex justify-content-center align-items-center mx-auto text-white bg-success rounded'
                            style={{ width: 56, height: 56 }}
                          >
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M5 18H9.24C9.37161 18.0008 9.50207 17.9755 9.62391 17.9258C9.74574 17.876 9.85656 17.8027 9.95 17.71L16.87 10.78L19.71 8C19.8037 7.90704 19.8781 7.79644 19.9289 7.67458C19.9797 7.55272 20.0058 7.42201 20.0058 7.29C20.0058 7.15799 19.9797 7.02728 19.9289 6.90542C19.8781 6.78356 19.8037 6.67296 19.71 6.58L15.47 2.29C15.377 2.19627 15.2664 2.12188 15.1446 2.07111C15.0227 2.02034 14.892 1.9942 14.76 1.9942C14.628 1.9942 14.4973 2.02034 14.3754 2.07111C14.2536 2.12188 14.143 2.19627 14.05 2.29L11.23 5.12L4.29 12.05C4.19732 12.1434 4.12399 12.2543 4.07423 12.3761C4.02446 12.4979 3.99924 12.6284 4 12.76V17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18ZM14.76 4.41L17.59 7.24L16.17 8.66L13.34 5.83L14.76 4.41ZM6 13.17L11.93 7.24L14.76 10.07L8.83 16H6V13.17ZM21 20H3C2.73478 20 2.48043 20.1054 2.29289 20.2929C2.10536 20.4804 2 20.7348 2 21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21C22 20.7348 21.8946 20.4804 21.7071 20.2929C21.5196 20.1054 21.2652 20 21 20Z'
                                fill='currentColor'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='col'>
                          <h3 className='font-heading fs-8 mb-4 mt-3'>
                            Chaque offre doit être crédible!
                          </h3>
                          <p className='mb-0 fw-medium text-secondary'>
                            Pourtant faire une offre ne suffit pas. Encore
                            faut-il qu'elle montre au vendeur que vous prétez
                            une attention réelle a son bien et que vous avez des
                            arguments. OfferAI fait cela pour vous en quelques
                            secondes.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mw-md'>
                      <div className='row g-6'>
                        <div className='col-auto'>
                          <div
                            className='mb-6 d-flex justify-content-center align-items-center mx-auto text-white bg-success rounded'
                            style={{ width: 56, height: 56 }}
                          >
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M10 13H3C2.73478 13 2.48043 13.1054 2.29289 13.2929C2.10536 13.4804 2 13.7348 2 14V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H10C10.2652 22 10.5196 21.8946 10.7071 21.7071C10.8946 21.5196 11 21.2652 11 21V14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13ZM9 20H4V15H9V20ZM21 2H14C13.7348 2 13.4804 2.10536 13.2929 2.29289C13.1054 2.48043 13 2.73478 13 3V10C13 10.2652 13.1054 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11H21C21.2652 11 21.5196 10.8946 21.7071 10.7071C21.8946 10.5196 22 10.2652 22 10V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2V2ZM20 9H15V4H20V9ZM21 13H14C13.7348 13 13.4804 13.1054 13.2929 13.2929C13.1054 13.4804 13 13.7348 13 14V21C13 21.2652 13.1054 21.5196 13.2929 21.7071C13.4804 21.8946 13.7348 22 14 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V14C22 13.7348 21.8946 13.4804 21.7071 13.2929C21.5196 13.1054 21.2652 13 21 13ZM20 20H15V15H20V20ZM10 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V10C2 10.2652 2.10536 10.5196 2.29289 10.7071C2.48043 10.8946 2.73478 11 3 11H10C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2V2ZM9 9H4V4H9V9Z'
                                fill='currentColor'
                              />
                            </svg>
                          </div>
                        </div>
                        <div className='col'>
                          <h3 className='font-heading fs-8 mb-4 mt-3'>
                            Passez du temps là où c'est important
                          </h3>
                          <p className='mb-0 fw-medium text-secondary'>
                            C'est seulement si une offre est acceptée ou tout du
                            moins qu'elle donne suite à une contre-proposition
                            intéressante qu'il est bon de passer du temps a
                            analyser en détails le bien, de faire des
                            estimations de travaux etc... OfferAi bien utilisé
                            peut vous faire gagner des dizaines d'heures!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row justify-content-center'>
                  <div className='col-auto'>
                    <div className='position-relative'>
                      <img
                        className='position-absolute top-0 start-0 mt-n8 ms-n8'
                        src='flex-assets/elements/circle-orange.svg'
                        alt=''
                      />
                      <img
                        className='position-absolute bottom-0 end-0 me-n8 mb-n9'
                        src='flex-assets/elements/dots-blue.svg'
                        alt=''
                      />
                      <img
                        className='img-fluid'
                        src='flex-assets/images/features/book.png'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className='position-relative py-28 bg-white overflow-hidden'
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          <div
            className='position-absolute top-0 start-0 h-100 w-100'
            style={{
              background:
                'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
            }}
          />
          <div className='position-relative container' style={{ zIndex: 1 }}>
            <div className='row align-items-center g-16'>
              <div className='col-12 col-md-6'>
                <div className='row justify-content-center'>
                  <div className='col-auto'>
                    <div className='position-relative'>
                      <img
                        className='position-absolute top-0 start-0 mt-n8 ms-n8'
                        src='flex-assets/elements/circle-orange.svg'
                        alt=''
                      />
                      <img
                        className='position-absolute bottom-0 end-0 me-n8 mb-n9'
                        src='flex-assets/elements/dots-blue.svg'
                        alt=''
                      />
                      <img
                        className='img-fluid'
                        src='images/Capture-d-ecran-2023-08-08-a-19-12-29.png'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='mw-md-xl'>
                  <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                    Features
                  </span>
                  <h2
                    className='font-heading mb-8 fs-5'
                    style={{ letterSpacing: '-1px' }}
                  >
                    Des offres comme si vous y étiez prêtes à l'emploie
                  </h2>
                  <p className='mb-6 fs-9 fw-medium text-secondary'>
                    OfferAi va annalyser votre profil d'investisseur au cordeau
                    et composer pour vous les offres les plus pertinantes, tout
                    en prenant en compte toutes les carractéristiques des biens
                    sans que vous ayez à tout détailler! Tout est renseigné pour
                    vous de l'entête à la date en passant par la localisation.
                  </p>
                  <div className='row'>
                    <div className='col-md-auto'>
                      <a
                        className='btn btn-lg btn-success fs-11 py-3 px-4 w-100 text-success-light shadow'
                        href='#'
                      >
                        ESSAYER GRATUITEMENT
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id='subscribe'
          className='position-relative py-20 bg-secondary-dark overflow-hidden'
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-dark.png")',
          }}
        >
          <div
            className='position-absolute top-0 start-0 h-100 w-100'
            style={{
              background:
                'radial-gradient(50% 50% at 50% 50%, rgba(42, 51, 66, 0) 0%, #2A3342 100%)',
            }}
          />
          <img
            className='position-absolute top-0 start-0 mt-6 ms-6'
            style={{ zIndex: 1 }}
            src='flex-assets/elements/dots-blue.svg'
            alt=''
          />
          <img
            className='position-absolute end-0 bottom-0 mb-6 me-6'
            style={{ zIndex: 1 }}
            src='flex-assets/elements/dots-blue.svg'
            alt=''
          />
          <div className='position-relative container' style={{ zIndex: 1 }}>
            <div className='mb-8 text-center mw-md-2xl mx-auto'>
              <h2
                className='font-heading mb-4 fs-6 text-white'
                style={{ letterSpacing: '-1px' }}
              >
                Restez informé&nbsp;
              </h2>
              <p
                className='fs-9 fw-medium text-secondary-light'
                contentEditable='false'
              >
                recevez les news sur les logiciels développés par innoestate
                pour les investisseurs immobiliers en quête de rentabilité&nbsp;
              </p>
            </div>
            <div className='mw-md-md mx-auto'>
              <div className='mb-2 row justify-content-center g-4 g-md-6'>
                <div className='col'>
                  <div className='form-group'>
                    <input
                      className='form-control text-secondary p-3 shadow'
                      id='newsletterInput3-1'
                      type='email'
                      aria-describedby='emailHelp'
                      placeholder='Enter your email'
                    />
                  </div>
                </div>
                <div className='col-12 col-md-auto clickable' onClick={sendEmail}>
                  <a
                    className='btn btn-lg btn-success fs-11 w-100 py-3 px-4 h-100 shadow'
                    href='#'
                  >
                    Souscrire
                  </a>
                </div>
              </div>
              <p className='mb-0 fs-13 fw-medium text-secondary'></p>
            </div>
          </div>
        </section>
        <section
          className='position-relative pt-20 bg-secondary-dark overflow-hidden'
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-dark.png")',
          }}
        >
          <div
            className='position-absolute top-0 start-0 h-100 w-100'
            style={{
              background:
                'radial-gradient(50% 50% at 50% 50%, rgba(42, 51, 66, 0) 0%, #2A3342 100%)',
            }}
          />
          <div className='position-relative container' style={{ zIndex: 1 }}>
            <div className='d-flex justify-content-center mb-2'>
              <a href='#'></a>
            </div>
          </div>
          <div
            className='position-relative border-bottom border-light-dark'
            style={{ zIndex: 1 }}
          />
          <div className='position-relative container' style={{ zIndex: 1 }}>
            <p className='mb-0 pt-10 pb-20 fs-9 fw-medium text-center text-secondary-light'>
              Innoestate Holdings&nbsp; Bureau 3, 6 boulevard Berthelot&nbsp;
              34000 Montpellier © 2023 offerAI. All rights reserved.
            </p>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

