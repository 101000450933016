import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Pricing() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='overflow-hidden'>
          <nav className='navbar navbar-dark navbar-expand-lg bg-white py-5 py-lg-1'>
            <div className='container-fluid'>
              <a className='navbar-brand' href='#'>
                <img
                  className='img-fluid'
                  src='flex-assets/logos/flex-dark-green.svg'
                  alt=''
                />
              </a>
              <div className='collapse navbar-collapse justify-content-center'>
                <ul className='navbar-nav'>
                  <li className='nav-item p-6'>
                    <a className='nav-link link-secondary-dark p-0' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='nav-item p-6'>
                    <a className='nav-link link-secondary p-0' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='nav-item p-6'>
                    <a className='nav-link link-secondary p-0' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li className='nav-item p-6'>
                    <a className='nav-link link-secondary p-0' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div className='d-none d-lg-block'>
                <div className='row align-items-center g-6'>
                  <div className='col-auto'>
                    <a className='link-secondary fs-11 fw-medium' href='#'>
                      Log In
                    </a>
                  </div>
                  <div className='col-auto'>
                    <a
                      className='btn btn-sm btn-success text-success-light shadow'
                      href='#'
                    >
                      Sign Up
                    </a>
                  </div>
                </div>
              </div>
              <div className='d-lg-none'>
                <a className='btn navbar-burger p-0' href='#'>
                  <svg
                    width={35}
                    height={35}
                    viewBox='0 0 32 32'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      className='text-light-light'
                      width={32}
                      height={32}
                      rx={6}
                      fill='currentColor'
                    />
                    <path
                      className='text-secondary'
                      d='M7 12H25C25.2652 12 25.5196 11.8946 25.7071 11.7071C25.8946 11.5196 26 11.2652 26 11C26 10.7348 25.8946 10.4804 25.7071 10.2929C25.5196 10.1054 25.2652 10 25 10H7C6.73478 10 6.48043 10.1054 6.29289 10.2929C6.10536 10.4804 6 10.7348 6 11C6 11.2652 6.10536 11.5196 6.29289 11.7071C6.48043 11.8946 6.73478 12 7 12ZM25 15H7C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16C6 16.2652 6.10536 16.5196 6.29289 16.7071C6.48043 16.8946 6.73478 17 7 17H25C25.2652 17 25.5196 16.8946 25.7071 16.7071C25.8946 16.5196 26 16.2652 26 16C26 15.7348 25.8946 15.4804 25.7071 15.2929C25.5196 15.1054 25.2652 15 25 15ZM25 20H7C6.73478 20 6.48043 20.1054 6.29289 20.2929C6.10536 20.4804 6 20.7348 6 21C6 21.2652 6.10536 21.5196 6.29289 21.7071C6.48043 21.8946 6.73478 22 7 22H25C25.2652 22 25.5196 21.8946 25.7071 21.7071C25.8946 21.5196 26 21.2652 26 21C26 20.7348 25.8946 20.4804 25.7071 20.2929C25.5196 20.1054 25.2652 20 25 20Z'
                      fill='currentColor'
                    />
                  </svg>
                </a>
              </div>
            </div>
          </nav>
          <div
            className='d-none navbar-menu position-fixed top-0 start-0 bottom-0 w-75 mw-xs'
            style={{ zIndex: 9999 }}
          >
            <div
              className='navbar-backdrop position-fixed top-0 start-0 end-0 bottom-0 bg-dark'
              style={{ opacity: '75%' }}
            />
            <nav className='position-relative h-100 w-100 d-flex flex-column justify-content-between p-6 bg-white overflow-auto'>
              <div className='d-flex align-items-center'>
                <a className='me-auto h4 mb-0 text-decoration-none' href='#'>
                  <img
                    className='img-fluid'
                    src='flex-assets/logos/flex-dark-green.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close text-secondary' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='currentColor'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-16'>
                <ul className='nav nav-pills flex-column'>
                  <li className='nav-item'>
                    <a
                      className='nav-link link-secondary-dark bg-light-light'
                      href='#'
                    >
                      Product
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link link-secondary' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link link-secondary' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link link-secondary' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div className='row align-items-center g-6'>
                <div className='col-12'>
                  <a
                    className='link-secondary fs-11 d-block w-100 text-center fw-medium'
                    href='#'
                  >
                    Log In
                  </a>
                </div>
                <div className='col-12'>
                  <a
                    className='btn btn-sm btn-success w-100 text-success-light shadow'
                    href='#'
                  >
                    Sign Up
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </section>
        <section
          className='position-relative pt-20 pt-md-0 bg-secondary-dark overflow-hidden'
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-dark.png")',
          }}
        >
          <div
            className='position-absolute top-0 start-0 h-100 w-100'
            style={{
              background:
                'radial-gradient(50% 50% at 50% 50%, rgba(42, 51, 66, 0) 0%, #2A3342 100%)',
            }}
          />
          <div
            className='position-relative row align-items-center g-16'
            style={{ zIndex: 1 }}
          >
            <div className='col-12 col-md-6'>
              <div className='mb-7 px-4 text-center mw-md mx-auto'>
                <div
                  className='mb-6 d-flex align-items-center justify-content-center mx-auto bg-success rounded-3'
                  style={{ width: 64, height: 64 }}
                >
                  <img
                    className='img-fluid'
                    style={{ height: 40 }}
                    src='flex-assets/images/sign-in/logo.svg'
                    alt=''
                  />
                </div>
                <h2 className='font-heading mb-4 fs-7 text-white'>
                  Sign in to your account
                </h2>
                <p className='mb-0 fs-9 fw-medium text-secondary-light'>
                  Start your demo version
                </p>
              </div>
              <form className='px-4 mw-sm mx-auto'>
                <div className='mb-4 row g-6'>
                  <div className='col-12'>
                    <div className='form-group'>
                      <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signInInput9-1'
                      >
                        Email
                      </label>
                      <input
                        className='form-control text-secondary-dark shadow'
                        id='signInInput9-1'
                        type='email'
                        aria-describedby='emailHelp'
                        defaultValue='support@shuffle.dev'
                        placeholder='Email'
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='form-group'>
                      <label
                        className='mb-1 fw-medium text-white'
                        htmlFor='signInInput9-2'
                      >
                        Password
                      </label>
                      <input
                        className='form-control text-secondary-dark shadow'
                        id='signInInput9-2'
                        type='password'
                        aria-describedby='passwordHelp'
                        defaultValue='**********************'
                        placeholder='Password'
                      />
                    </div>
                  </div>
                </div>
                <div className='mb-6 row align-items-center justify-content-between g-2'>
                  <div className='col-auto'>
                    <div className='form-check'>
                      <input
                        className='form-check-input shadow'
                        id='flexCheckDefault'
                        type='checkbox'
                        defaultValue=''
                      />
                      <label
                        className='form-check-label fs-13 fw-medium text-white'
                        htmlFor='flexCheckDefault'
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <div className='col-auto'>
                    <a
                      className='fs-13 fw-medium text-success link-success'
                      href='#'
                    >
                      Forgot your password?
                    </a>
                  </div>
                </div>
                <div className='mb-6 row'>
                  <div className='col-12'>
                    <button
                      className='btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow'
                      type='submit'
                    >
                      Sign In
                    </button>
                  </div>
                </div>
                <p className='mb-0 fs-13 fw-medium text-white text-center'>
                  <span>Don’t have an account?</span>
                  <a className='text-success link-success' href='#'>
                    Sign up
                  </a>
                </p>
              </form>
            </div>
            <div className='col-12 col-md-6'>
              <div className='py-36 px-4 bg-success h-100'>
                <div className='text-center mw-md-xl mx-auto'>
                  <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                    Quotes
                  </span>
                  <div className='mb-20 position-relative'>
                    <h2
                      className='position-relative font-heading fs-7 fw-medium text-white'
                      style={{ zIndex: 1 }}
                    >
                      Love the simplicity of the service and the prompt customer
                      support. We can’t imagine working without it.
                    </h2>
                    <img
                      className='position-absolute top-0 start-0 ms-n12 mt-n10'
                      src='flex-assets/images/sign-in/quote-top-green.svg'
                      alt=''
                    />
                    <img
                      className='position-absolute bottom-0 end-0 me-n10 mb-n16'
                      src='flex-assets/images/sign-in/quote-down-green.svg'
                      alt=''
                    />
                  </div>
                  <img
                    className='mb-6 img-fluid'
                    src='flex-assets/images/sign-in/avatar.png'
                    alt=''
                  />
                  <h3 className='font-heading mb-1 fs-10 fw-semibold text-white'>
                    John Doe
                  </h3>
                  <p className='mb-8 fs-10 text-success-light'>
                    CEO &amp; Founder at Flex.co
                  </p>
                  <div className='row justify-content-center g-3'>
                    <div className='col-auto'>
                      <a
                        className='d-inline-block rounded-pill bg-success-light bg-opacity-50'
                        style={{ width: 12, height: 12 }}
                        href='#'
                      />
                    </div>
                    <div className='col-auto'>
                      <a
                        className='d-inline-block rounded-pill bg-white'
                        style={{ width: 12, height: 12 }}
                        href='#'
                      />
                    </div>
                    <div className='col-auto'>
                      <a
                        className='d-inline-block rounded-pill bg-success-light bg-opacity-50'
                        style={{ width: 12, height: 12 }}
                        href='#'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className='position-relative py-20 bg-white overflow-hidden'
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          <div
            className='position-absolute top-0 start-0 h-100 w-100'
            style={{
              background:
                'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
            }}
          />
          <div className='position-relative container' style={{ zIndex: 1 }}>
            <div className='mb-16 text-center'>
              <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                Pricing
              </span>
              <h2
                className='font-heading mb-4 fs-5'
                style={{ letterSpacing: '-1px' }}
              >
                Flexible pricing plan for your startup
              </h2>
              <p className='mb-6 fs-9 fw-medium text-secondary'>
                Pricing that scales with your business immediately.
              </p>
              <div className='d-inline-flex shadow'>
                <a
                  className='d-inline-flex px-4 py-3 fs-12 fw-medium text-success bg-white border rounded-start'
                  href='#'
                >
                  Billed Monthly
                </a>
                <a
                  className='d-inline-flex px-4 py-3 fs-12 fw-medium text-secondary bg-light-light border rounded-end'
                  href='#'
                >
                  Billed Annually
                </a>
              </div>
            </div>
            <div className='row g-8'>
              <div className='col-12 col-md-6'>
                <div className='bg-light-light rounded shadow'>
                  <div className='border-bottom border-light'>
                    <div className='px-8 py-4'>
                      <div className='row justify-content-between align-items-center g-4'>
                        <div className='col-auto'>
                          <h3 className='mb-0 fs-7 fw-semibold'>Basic plan</h3>
                          <p className='mb-0 fw-medium text-secondary-light'>
                            For bigger teams
                          </p>
                        </div>
                        <div className='col-auto'>
                          <div className='d-flex'>
                            <div className='fs-7 fw-bold mt-1 pe-1'>$</div>
                            <div className='fs-4 fw-semibold'>
                              <span>99</span>
                              <span className='fs-11 text-secondary'>/mo</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom border-light'>
                    <div className='p-8'>
                      <ul className='mb-0 list-unstyled'>
                        <li className='mb-4 d-flex align-items-start'>
                          <img
                            className='me-2'
                            src='flex-assets/elements/checkbox-green.svg'
                            alt=''
                          />
                          <p className='mb-0 fs-11 fw-medium text-secondary'>
                            Access to all features
                          </p>
                        </li>
                        <li className='mb-4 d-flex align-items-start'>
                          <img
                            className='me-2'
                            src='flex-assets/elements/checkbox-green.svg'
                            alt=''
                          />
                          <p className='mb-0 fs-11 fw-medium text-secondary'>
                            Assisted onboarding support
                          </p>
                        </li>
                        <li className='mb-4 d-flex align-items-start'>
                          <img
                            className='me-2'
                            src='flex-assets/elements/checkbox-green.svg'
                            alt=''
                          />
                          <p className='mb-0 fs-11 fw-medium text-secondary'>
                            CPM Overage: Unlimited
                          </p>
                        </li>
                        <li className='d-flex align-items-start'>
                          <img
                            className='me-2'
                            src='flex-assets/elements/checkbox-green.svg'
                            alt=''
                          />
                          <p className='mb-0 fs-11 fw-medium text-secondary'>
                            Program reviews 1x a month
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='p-8'>
                    <a
                      className='btn btn-lg btn-success fs-10 py-4 w-100 text-success-light shadow'
                      href='#'
                    >
                      Get Started Now
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='bg-light-light rounded shadow'>
                  <div className='border-bottom border-light'>
                    <div className='px-8 py-4'>
                      <div className='row justify-content-between align-items-center g-4'>
                        <div className='col-auto'>
                          <h3 className='mb-0 fs-7 fw-semibold'>Lifetime</h3>
                          <p className='mb-0 fw-medium text-secondary-light'>
                            Unlimited possibilities
                          </p>
                        </div>
                        <div className='col-auto'>
                          <div className='d-flex'>
                            <div className='fs-7 fw-bold mt-1 pe-1'>$</div>
                            <div className='fs-4 fw-semibold'>
                              <span>189</span>
                              <span className='fs-11 text-secondary'>/mo</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom border-light'>
                    <div className='p-8'>
                      <ul className='mb-0 list-unstyled'>
                        <li className='mb-4 d-flex align-items-start'>
                          <img
                            className='me-2'
                            src='flex-assets/elements/checkbox-green.svg'
                            alt=''
                          />
                          <p className='mb-0 fs-11 fw-medium text-secondary'>
                            Access to all features
                          </p>
                        </li>
                        <li className='mb-4 d-flex align-items-start'>
                          <img
                            className='me-2'
                            src='flex-assets/elements/checkbox-green.svg'
                            alt=''
                          />
                          <p className='mb-0 fs-11 fw-medium text-secondary'>
                            Assisted onboarding support
                          </p>
                        </li>
                        <li className='mb-4 d-flex align-items-start'>
                          <img
                            className='me-2'
                            src='flex-assets/elements/checkbox-green.svg'
                            alt=''
                          />
                          <p className='mb-0 fs-11 fw-medium text-secondary'>
                            CPM Overage: Unlimited
                          </p>
                        </li>
                        <li className='d-flex align-items-start'>
                          <img
                            className='me-2'
                            src='flex-assets/elements/checkbox-green.svg'
                            alt=''
                          />
                          <p className='mb-0 fs-11 fw-medium text-secondary'>
                            Program reviews 1x a month
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='p-8'>
                    <a
                      className='btn btn-lg btn-success fs-10 py-4 w-100 text-success-light shadow'
                      href='#'
                    >
                      Get Started Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className='position-relative py-28 bg-white overflow-hidden'
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          <div
            className='position-absolute top-0 start-0 h-100 w-100'
            style={{
              background:
                'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
            }}
          />
          <div className='container'>
            <div className='position-relative' style={{ zIndex: 10 }}>
              <div className='row align-items-center g-16'>
                <div className='col-12 col-md-6'>
                  <div className='mw-md-md'>
                    <h2
                      className='mb-4 fs-6 font-heading'
                      style={{ letterSpacing: '-1px' }}
                    >
                      The fastest way from idea to live site. Period.
                    </h2>
                    <p className='mb-0 fs-9 fw-medium text-secondary'>
                      Flex is a Small SaaS Business. Flex isn’t a traditional
                      company.
                    </p>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='row justify-content-end g-4'>
                    <div className='col-12 col-md-auto'>
                      <a
                        className='btn btn-success w-100 px-7 py-4 fs-10 fw-medium text-success-light rounded shadow'
                        href='#'
                      >
                        Get Started
                      </a>
                    </div>
                    <div className='col-12 col-md-auto'>
                      <div className='bg-white rounded'>
                        <a
                          className='btn btn-outline-light w-100 px-7 py-4 fs-10 fw-medium text-dark border rounded shadow'
                          href='#'
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className='position-relative py-20 bg-white overflow-hidden'
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          <div
            className='position-absolute top-0 start-0 h-100 w-100'
            style={{
              background:
                'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
            }}
          />
          <div className='position-relative container' style={{ zIndex: 1 }}>
            <div className='row g-16'>
              <div className='col-12 col-md-6'>
                <div className='mw-md-md'>
                  <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                    Faq
                  </span>
                  <h2
                    className='font-heading mb-4 fs-5'
                    style={{ letterSpacing: '-1px' }}
                  >
                    Frequently Asked Questions
                  </h2>
                  <p className='mb-0 fs-9 fw-medium text-secondary'>
                    Flex is the only saas business platform that lets you run
                    your business on one platform, seamlessly across all digital
                    channels.
                  </p>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='mw-md-lg ms-auto'>
                  <div className='row g-10'>
                    <div className='col-12'>
                      <a className='text-success link-success' href='#'>
                        <div className='mb-4 row justify-content-between g-4'>
                          <div className='col-auto'>
                            <h3 className='mb-0 font-heading fs-9'>
                              How long does it take to ship my order?
                            </h3>
                          </div>
                          <div className='col-auto'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M12.71 8.29C12.6149 8.19896 12.5028 8.12759 12.38 8.08C12.1365 7.97998 11.8635 7.97998 11.62 8.08C11.4972 8.12759 11.3851 8.19896 11.29 8.29L8.29 11.29C8.1017 11.4783 7.99591 11.7337 7.99591 12C7.99591 12.2663 8.1017 12.5217 8.29 12.71C8.4783 12.8983 8.7337 13.0041 9 13.0041C9.2663 13.0041 9.5217 12.8983 9.71 12.71L11 11.41L11 15C11 15.2652 11.1054 15.5196 11.2929 15.7071C11.4804 15.8946 11.7348 16 12 16C12.2652 16 12.5196 15.8946 12.7071 15.7071C12.8946 15.5196 13 15.2652 13 15L13 11.41L14.29 12.71C14.383 12.8037 14.4936 12.8781 14.6154 12.9289C14.7373 12.9797 14.868 13.0058 15 13.0058C15.132 13.0058 15.2627 12.9797 15.3846 12.9289C15.5064 12.8781 15.617 12.8037 15.71 12.71C15.8037 12.617 15.8781 12.5064 15.9289 12.3846C15.9797 12.2627 16.0058 12.132 16.0058 12C16.0058 11.868 15.9797 11.7373 15.9289 11.6154C15.8781 11.4936 15.8037 11.383 15.71 11.29L12.71 8.29ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51808 6.3459 2.7612 8.17316C2.00433 10.0004 1.80629 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34783 20.9464 6.80429 19.0711 4.92893C18.1425 4.00034 17.0401 3.26375 15.8268 2.7612C14.6136 2.25865 13.3132 2 12 2ZM12 20C10.4177 20 8.87103 19.5308 7.55544 18.6518C6.23984 17.7727 5.21446 16.5233 4.60896 15.0615C4.00346 13.5997 3.84503 11.9911 4.15372 10.4393C4.4624 8.88743 5.22432 7.46196 6.34314 6.34314C7.46196 5.22432 8.88743 4.4624 10.4393 4.15371C11.9911 3.84503 13.5997 4.00346 15.0615 4.60896C16.5233 5.21446 17.7727 6.23984 18.6518 7.55543C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z'
                                fill='currentColor'
                              />
                            </svg>
                          </div>
                        </div>
                        <p className='mb-0 fw-medium text-secondary'>
                          Orders are usually shipped within 1-2 business days
                          after placing the order.
                        </p>
                      </a>
                    </div>
                    <div className='col-12'>
                      <a className='text-success link-success' href='#'>
                        <div className='row justify-content-between g-4'>
                          <div className='col-auto'>
                            <h3 className='mb-0 font-heading fs-9'>
                              What payment methods do you accept?
                            </h3>
                          </div>
                          <div className='col-auto'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M11.29 15.71C11.3851 15.801 11.4972 15.8724 11.62 15.92C11.8635 16.02 12.1365 16.02 12.38 15.92C12.5028 15.8724 12.6149 15.801 12.71 15.71L15.71 12.71C15.8983 12.5217 16.0041 12.2663 16.0041 12C16.0041 11.7337 15.8983 11.4783 15.71 11.29C15.5217 11.1017 15.2663 10.9959 15 10.9959C14.7337 10.9959 14.4783 11.1017 14.29 11.29L13 12.59L13 9C13 8.73479 12.8946 8.48043 12.7071 8.2929C12.5196 8.10536 12.2652 8 12 8C11.7348 8 11.4804 8.10536 11.2929 8.2929C11.1054 8.48043 11 8.73479 11 9L11 12.59L9.71 11.29C9.61704 11.1963 9.50644 11.1219 9.38458 11.0711C9.26272 11.0203 9.13201 10.9942 9 10.9942C8.86799 10.9942 8.73728 11.0203 8.61542 11.0711C8.49356 11.1219 8.38296 11.1963 8.29 11.29C8.19627 11.383 8.12188 11.4936 8.07111 11.6154C8.02034 11.7373 7.9942 11.868 7.9942 12C7.9942 12.132 8.02034 12.2627 8.07111 12.3846C8.12188 12.5064 8.19627 12.617 8.29 12.71L11.29 15.71ZM12 22C13.9778 22 15.9112 21.4135 17.5557 20.3147C19.2002 19.2159 20.4819 17.6541 21.2388 15.8268C21.9957 13.9996 22.1937 11.9889 21.8079 10.0491C21.422 8.10929 20.4696 6.32746 19.0711 4.92894C17.6725 3.53041 15.8907 2.578 13.9509 2.19215C12.0111 1.8063 10.0004 2.00433 8.17317 2.76121C6.3459 3.51809 4.78412 4.79981 3.6853 6.4443C2.58649 8.08879 2 10.0222 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7363 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22ZM12 4C13.5823 4 15.129 4.4692 16.4446 5.34825C17.7602 6.2273 18.7855 7.47673 19.391 8.93854C19.9965 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569C16.538 18.7757 15.1126 19.5376 13.5607 19.8463C12.0089 20.155 10.4003 19.9965 8.93853 19.391C7.47672 18.7855 6.22729 17.7602 5.34824 16.4446C4.46919 15.129 4 13.5823 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84286 9.87827 4 12 4Z'
                                fill='currentColor'
                              />
                            </svg>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='col-12'>
                      <a className='text-success link-success' href='#'>
                        <div className='row justify-content-between g-4'>
                          <div className='col-auto'>
                            <h3 className='mb-0 font-heading fs-9'>
                              What shipping options do you have?
                            </h3>
                          </div>
                          <div className='col-auto'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M11.29 15.71C11.3851 15.801 11.4972 15.8724 11.62 15.92C11.8635 16.02 12.1365 16.02 12.38 15.92C12.5028 15.8724 12.6149 15.801 12.71 15.71L15.71 12.71C15.8983 12.5217 16.0041 12.2663 16.0041 12C16.0041 11.7337 15.8983 11.4783 15.71 11.29C15.5217 11.1017 15.2663 10.9959 15 10.9959C14.7337 10.9959 14.4783 11.1017 14.29 11.29L13 12.59L13 9C13 8.73479 12.8946 8.48043 12.7071 8.2929C12.5196 8.10536 12.2652 8 12 8C11.7348 8 11.4804 8.10536 11.2929 8.2929C11.1054 8.48043 11 8.73479 11 9L11 12.59L9.71 11.29C9.61704 11.1963 9.50644 11.1219 9.38458 11.0711C9.26272 11.0203 9.13201 10.9942 9 10.9942C8.86799 10.9942 8.73728 11.0203 8.61542 11.0711C8.49356 11.1219 8.38296 11.1963 8.29 11.29C8.19627 11.383 8.12188 11.4936 8.07111 11.6154C8.02034 11.7373 7.9942 11.868 7.9942 12C7.9942 12.132 8.02034 12.2627 8.07111 12.3846C8.12188 12.5064 8.19627 12.617 8.29 12.71L11.29 15.71ZM12 22C13.9778 22 15.9112 21.4135 17.5557 20.3147C19.2002 19.2159 20.4819 17.6541 21.2388 15.8268C21.9957 13.9996 22.1937 11.9889 21.8079 10.0491C21.422 8.10929 20.4696 6.32746 19.0711 4.92894C17.6725 3.53041 15.8907 2.578 13.9509 2.19215C12.0111 1.8063 10.0004 2.00433 8.17317 2.76121C6.3459 3.51809 4.78412 4.79981 3.6853 6.4443C2.58649 8.08879 2 10.0222 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7363 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22ZM12 4C13.5823 4 15.129 4.4692 16.4446 5.34825C17.7602 6.2273 18.7855 7.47673 19.391 8.93854C19.9965 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569C16.538 18.7757 15.1126 19.5376 13.5607 19.8463C12.0089 20.155 10.4003 19.9965 8.93853 19.391C7.47672 18.7855 6.22729 17.7602 5.34824 16.4446C4.46919 15.129 4 13.5823 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84286 9.87827 4 12 4Z'
                                fill='currentColor'
                              />
                            </svg>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='col-12'>
                      <a className='text-success link-success' href='#'>
                        <div className='row justify-content-between g-4'>
                          <div className='col-auto'>
                            <h3 className='mb-0 font-heading fs-9'>
                              How do I make changes to an existing order?
                            </h3>
                          </div>
                          <div className='col-auto'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M11.29 15.71C11.3851 15.801 11.4972 15.8724 11.62 15.92C11.8635 16.02 12.1365 16.02 12.38 15.92C12.5028 15.8724 12.6149 15.801 12.71 15.71L15.71 12.71C15.8983 12.5217 16.0041 12.2663 16.0041 12C16.0041 11.7337 15.8983 11.4783 15.71 11.29C15.5217 11.1017 15.2663 10.9959 15 10.9959C14.7337 10.9959 14.4783 11.1017 14.29 11.29L13 12.59L13 9C13 8.73479 12.8946 8.48043 12.7071 8.2929C12.5196 8.10536 12.2652 8 12 8C11.7348 8 11.4804 8.10536 11.2929 8.2929C11.1054 8.48043 11 8.73479 11 9L11 12.59L9.71 11.29C9.61704 11.1963 9.50644 11.1219 9.38458 11.0711C9.26272 11.0203 9.13201 10.9942 9 10.9942C8.86799 10.9942 8.73728 11.0203 8.61542 11.0711C8.49356 11.1219 8.38296 11.1963 8.29 11.29C8.19627 11.383 8.12188 11.4936 8.07111 11.6154C8.02034 11.7373 7.9942 11.868 7.9942 12C7.9942 12.132 8.02034 12.2627 8.07111 12.3846C8.12188 12.5064 8.19627 12.617 8.29 12.71L11.29 15.71ZM12 22C13.9778 22 15.9112 21.4135 17.5557 20.3147C19.2002 19.2159 20.4819 17.6541 21.2388 15.8268C21.9957 13.9996 22.1937 11.9889 21.8079 10.0491C21.422 8.10929 20.4696 6.32746 19.0711 4.92894C17.6725 3.53041 15.8907 2.578 13.9509 2.19215C12.0111 1.8063 10.0004 2.00433 8.17317 2.76121C6.3459 3.51809 4.78412 4.79981 3.6853 6.4443C2.58649 8.08879 2 10.0222 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7363 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22ZM12 4C13.5823 4 15.129 4.4692 16.4446 5.34825C17.7602 6.2273 18.7855 7.47673 19.391 8.93854C19.9965 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569C16.538 18.7757 15.1126 19.5376 13.5607 19.8463C12.0089 20.155 10.4003 19.9965 8.93853 19.391C7.47672 18.7855 6.22729 17.7602 5.34824 16.4446C4.46919 15.129 4 13.5823 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84286 9.87827 4 12 4Z'
                                fill='currentColor'
                              />
                            </svg>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='col-12'>
                      <a className='text-success link-success' href='#'>
                        <div className='row justify-content-between g-4'>
                          <div className='col-auto'>
                            <h3 className='mb-0 font-heading fs-9'>
                              When will my order arrive?
                            </h3>
                          </div>
                          <div className='col-auto'>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M11.29 15.71C11.3851 15.801 11.4972 15.8724 11.62 15.92C11.8635 16.02 12.1365 16.02 12.38 15.92C12.5028 15.8724 12.6149 15.801 12.71 15.71L15.71 12.71C15.8983 12.5217 16.0041 12.2663 16.0041 12C16.0041 11.7337 15.8983 11.4783 15.71 11.29C15.5217 11.1017 15.2663 10.9959 15 10.9959C14.7337 10.9959 14.4783 11.1017 14.29 11.29L13 12.59L13 9C13 8.73479 12.8946 8.48043 12.7071 8.2929C12.5196 8.10536 12.2652 8 12 8C11.7348 8 11.4804 8.10536 11.2929 8.2929C11.1054 8.48043 11 8.73479 11 9L11 12.59L9.71 11.29C9.61704 11.1963 9.50644 11.1219 9.38458 11.0711C9.26272 11.0203 9.13201 10.9942 9 10.9942C8.86799 10.9942 8.73728 11.0203 8.61542 11.0711C8.49356 11.1219 8.38296 11.1963 8.29 11.29C8.19627 11.383 8.12188 11.4936 8.07111 11.6154C8.02034 11.7373 7.9942 11.868 7.9942 12C7.9942 12.132 8.02034 12.2627 8.07111 12.3846C8.12188 12.5064 8.19627 12.617 8.29 12.71L11.29 15.71ZM12 22C13.9778 22 15.9112 21.4135 17.5557 20.3147C19.2002 19.2159 20.4819 17.6541 21.2388 15.8268C21.9957 13.9996 22.1937 11.9889 21.8079 10.0491C21.422 8.10929 20.4696 6.32746 19.0711 4.92894C17.6725 3.53041 15.8907 2.578 13.9509 2.19215C12.0111 1.8063 10.0004 2.00433 8.17317 2.76121C6.3459 3.51809 4.78412 4.79981 3.6853 6.4443C2.58649 8.08879 2 10.0222 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7363 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22ZM12 4C13.5823 4 15.129 4.4692 16.4446 5.34825C17.7602 6.2273 18.7855 7.47673 19.391 8.93854C19.9965 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569C16.538 18.7757 15.1126 19.5376 13.5607 19.8463C12.0089 20.155 10.4003 19.9965 8.93853 19.391C7.47672 18.7855 6.22729 17.7602 5.34824 16.4446C4.46919 15.129 4 13.5823 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84286 9.87827 4 12 4Z'
                                fill='currentColor'
                              />
                            </svg>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className='position-relative pt-20 bg-white overflow-hidden'
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          <div
            className='position-absolute top-0 start-0 h-100 w-100'
            style={{
              background:
                'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
            }}
          />
          <div
            className='position-relative container mb-6'
            style={{ zIndex: 1 }}
          >
            <div className='row align-items-center g-16'>
              <div className='col-12 col-md-8'>
                <a className='d-inline-block mb-8' href='#'>
                  <img
                    className='img-fluid'
                    src='flex-assets/logos/flex-dark-green.svg'
                    alt=''
                  />
                </a>
                <p className='mb-6 fs-10 fw-medium text-secondary-light mw-md-sm'>
                  Launch your own Software As A Service Application with Flex
                  Solutions.
                </p>
                <ul className='nav ms-n6'>
                  <li className='nav-item'>
                    <a className='nav-link p-6 fs-11 link-secondary' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link p-6 fs-11 link-secondary' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link p-6 fs-11 link-secondary' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link p-6 fs-11 link-secondary' href='#'>
                      Resources
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link p-6 fs-11 link-secondary' href='#'>
                      Careers
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link p-6 fs-11 link-secondary' href='#'>
                      Help
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link p-6 fs-11 link-secondary' href='#'>
                      Privacy
                    </a>
                  </li>
                </ul>
              </div>
              <div className='col-12 col-md-4'>
                <div className='row flex-column align-items-md-end pe-16 g-4'>
                  <div className='col-auto'>
                    <a href='#'>
                      <img src='flex-assets/images/footers/google-play.svg' />
                    </a>
                  </div>
                  <div className='col-auto'>
                    <a href='#'>
                      <img src='flex-assets/images/footers/apps-store.svg' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='position-relative border-bottom border-light'
            style={{ zIndex: 1 }}
          />
          <div className='position-relative container' style={{ zIndex: 1 }}>
            <div className='row align-items-center justify-content-between pt-12 pb-36'>
              <div className='col-auto'>
                <p className='mb-0 fs-11 fw-medium text-secondary-light'>
                  © 2021 Flex. All rights reserved.
                </p>
              </div>
              <div className='col-auto'>
                <ul className='nav justify-content-md-end'>
                  <li className='nav-item'>
                    <a
                      className='nav-link p-4 fs-9 link-secondary-light opacity-50'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M13.6349 20.7273V12.766H16.3583L16.7669 9.66246H13.6349V7.68128C13.6349 6.78301 13.8882 6.17085 15.203 6.17085L16.8771 6.17017V3.39424C16.5876 3.35733 15.5938 3.27273 14.4371 3.27273C12.0217 3.27273 10.3681 4.71881 10.3681 7.37391V9.66246H7.63647V12.766H10.3681V20.7273H13.6349Z'
                          fill='currentcolor'
                        />
                        <mask
                          id='mask0_352_3312'
                          style={{ maskType: 'alpha' }}
                          maskUnits='userSpaceOnUse'
                          x={7}
                          y={3}
                          width={10}
                          height={18}
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M13.6349 20.7273V12.766H16.3583L16.7669 9.66246H13.6349V7.68128C13.6349 6.78301 13.8882 6.17085 15.203 6.17085L16.8771 6.17017V3.39424C16.5876 3.35733 15.5938 3.27273 14.4371 3.27273C12.0217 3.27273 10.3681 4.71881 10.3681 7.37391V9.66246H7.63647V12.766H10.3681V20.7273H13.6349Z'
                            fill='white'
                          />
                        </mask>
                        <g mask='url(#mask0_352_3312)' />
                      </svg>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link p-4 fs-9 link-secondary-light opacity-50'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M21.8181 6.14597C21.1356 6.44842 20.4032 6.65355 19.6336 6.74512C20.4194 6.27461 21.0208 5.5283 21.3059 4.64176C20.5689 5.07748 19.7553 5.39388 18.8885 5.56539C18.1943 4.82488 17.2069 4.36364 16.1118 4.36364C14.0108 4.36364 12.3072 6.06718 12.3072 8.16706C12.3072 8.46488 12.3408 8.75576 12.4057 9.03391C9.24434 8.87512 6.44104 7.36048 4.56483 5.05894C4.23686 5.61985 4.05028 6.27342 4.05028 6.97109C4.05028 8.29106 4.72243 9.45573 5.74225 10.1371C5.11877 10.1163 4.53237 9.94476 4.01901 9.65967V9.70718C4.01901 11.5498 5.33086 13.0876 7.07031 13.4376C6.75161 13.5234 6.41555 13.5709 6.06789 13.5709C5.82222 13.5709 5.58464 13.5465 5.35171 13.5002C5.8361 15.0125 7.24067 16.1123 8.90483 16.1424C7.6034 17.1622 5.96243 17.7683 4.1801 17.7683C3.87301 17.7683 3.57052 17.7498 3.27271 17.7162C4.95655 18.7974 6.95561 19.4278 9.10416 19.4278C16.1026 19.4278 19.928 13.6312 19.928 8.60397L19.9153 8.11145C20.6627 7.57833 21.3094 6.90851 21.8181 6.14597Z'
                          fill='currentcolor'
                        />
                        <mask
                          id='mask0_352_3313'
                          style={{ maskType: 'alpha' }}
                          maskUnits='userSpaceOnUse'
                          x={3}
                          y={4}
                          width={19}
                          height={16}
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M21.8181 6.14597C21.1356 6.44842 20.4032 6.65355 19.6336 6.74512C20.4194 6.27461 21.0208 5.5283 21.3059 4.64176C20.5689 5.07748 19.7553 5.39388 18.8885 5.56539C18.1943 4.82488 17.2069 4.36364 16.1118 4.36364C14.0108 4.36364 12.3072 6.06718 12.3072 8.16706C12.3072 8.46488 12.3408 8.75576 12.4057 9.03391C9.24434 8.87512 6.44104 7.36048 4.56483 5.05894C4.23686 5.61985 4.05028 6.27342 4.05028 6.97109C4.05028 8.29106 4.72243 9.45573 5.74225 10.1371C5.11877 10.1163 4.53237 9.94476 4.01901 9.65967V9.70718C4.01901 11.5498 5.33086 13.0876 7.07031 13.4376C6.75161 13.5234 6.41555 13.5709 6.06789 13.5709C5.82222 13.5709 5.58464 13.5465 5.35171 13.5002C5.8361 15.0125 7.24067 16.1123 8.90483 16.1424C7.6034 17.1622 5.96243 17.7683 4.1801 17.7683C3.87301 17.7683 3.57052 17.7498 3.27271 17.7162C4.95655 18.7974 6.95561 19.4278 9.10416 19.4278C16.1026 19.4278 19.928 13.6312 19.928 8.60397L19.9153 8.11145C20.6627 7.57833 21.3094 6.90851 21.8181 6.14597Z'
                            fill='white'
                          />
                        </mask>
                        <g mask='url(#mask0_352_3313)' />
                      </svg>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link p-4 fs-9 link-secondary-light opacity-50'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M7.6007 2.18182H16.3992C19.3874 2.18182 21.8184 4.61281 21.8182 7.60074V16.3993C21.8182 19.3872 19.3874 21.8182 16.3992 21.8182H7.6007C4.61276 21.8182 2.18188 19.3873 2.18188 16.3993V7.60074C2.18188 4.61281 4.61276 2.18182 7.6007 2.18182ZM16.3993 20.0759C18.4267 20.0759 20.0761 18.4266 20.0761 16.3993H20.076V7.60074C20.076 5.57348 18.4266 3.92405 16.3992 3.92405H7.6007C5.57343 3.92405 3.92412 5.57348 3.92412 7.60074V16.3993C3.92412 18.4266 5.57343 20.0761 7.6007 20.0759H16.3993ZM6.85721 12.0001C6.85721 9.16424 9.16425 6.85714 12.0001 6.85714C14.8359 6.85714 17.1429 9.16424 17.1429 12.0001C17.1429 14.8359 14.8359 17.1429 12.0001 17.1429C9.16425 17.1429 6.85721 14.8359 6.85721 12.0001ZM8.62805 12C8.62805 13.8593 10.1408 15.3719 12.0001 15.3719C13.8593 15.3719 15.3721 13.8593 15.3721 12C15.3721 10.1406 13.8594 8.6279 12.0001 8.6279C10.1407 8.6279 8.62805 10.1406 8.62805 12Z'
                          fill='currentcolor'
                        />
                        <mask
                          id='mask0_352_3314'
                          style={{ maskType: 'alpha' }}
                          maskUnits='userSpaceOnUse'
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M7.6007 2.18182H16.3992C19.3874 2.18182 21.8184 4.61281 21.8182 7.60074V16.3993C21.8182 19.3872 19.3874 21.8182 16.3992 21.8182H7.6007C4.61276 21.8182 2.18188 19.3873 2.18188 16.3993V7.60074C2.18188 4.61281 4.61276 2.18182 7.6007 2.18182ZM16.3993 20.0759C18.4267 20.0759 20.0761 18.4266 20.0761 16.3993H20.076V7.60074C20.076 5.57348 18.4266 3.92405 16.3992 3.92405H7.6007C5.57343 3.92405 3.92412 5.57348 3.92412 7.60074V16.3993C3.92412 18.4266 5.57343 20.0761 7.6007 20.0759H16.3993ZM6.85721 12.0001C6.85721 9.16424 9.16425 6.85714 12.0001 6.85714C14.8359 6.85714 17.1429 9.16424 17.1429 12.0001C17.1429 14.8359 14.8359 17.1429 12.0001 17.1429C9.16425 17.1429 6.85721 14.8359 6.85721 12.0001ZM8.62805 12C8.62805 13.8593 10.1408 15.3719 12.0001 15.3719C13.8593 15.3719 15.3721 13.8593 15.3721 12C15.3721 10.1406 13.8594 8.6279 12.0001 8.6279C10.1407 8.6279 8.62805 10.1406 8.62805 12Z'
                            fill='white'
                          />
                        </mask>
                        <g mask='url(#mask0_352_3314)' />
                      </svg>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link p-4 fs-9 link-secondary-light opacity-50'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M12 3C7.0275 3 3 7.13211 3 12.2284C3 16.3065 5.5785 19.7648 9.15375 20.9841C9.60375 21.0709 9.76875 20.7853 9.76875 20.5403C9.76875 20.3212 9.76125 19.7405 9.7575 18.9712C7.254 19.5277 6.726 17.7332 6.726 17.7332C6.3165 16.6681 5.72475 16.3832 5.72475 16.3832C4.9095 15.8111 5.78775 15.8229 5.78775 15.8229C6.6915 15.887 7.16625 16.7737 7.16625 16.7737C7.96875 18.1847 9.273 17.777 9.7875 17.5414C9.8685 16.9443 10.1003 16.5381 10.3575 16.3073C8.35875 16.0764 6.258 15.2829 6.258 11.7471C6.258 10.7399 6.60675 9.91659 7.18425 9.27095C7.083 9.03774 6.77925 8.0994 7.263 6.82846C7.263 6.82846 8.01675 6.58116 9.738 7.77462C10.458 7.56958 11.223 7.46785 11.988 7.46315C12.753 7.46785 13.518 7.56958 14.238 7.77462C15.948 6.58116 16.7017 6.82846 16.7017 6.82846C17.1855 8.0994 16.8818 9.03774 16.7917 9.27095C17.3655 9.91659 17.7142 10.7399 17.7142 11.7471C17.7142 15.2923 15.6105 16.0725 13.608 16.2995C13.923 16.5765 14.2155 17.1423 14.2155 18.0071C14.2155 19.242 14.2043 20.2344 14.2043 20.5341C14.2043 20.7759 14.3617 21.0647 14.823 20.9723C18.4237 19.7609 21 16.3002 21 12.2284C21 7.13211 16.9703 3 12 3Z'
                          fill='currentcolor'
                        />
                      </svg>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link p-4 fs-9 link-secondary-light opacity-50'
                      href='#'
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M19.2 3H4.8C3.81 3 3 3.81 3 4.8V19.2C3 20.19 3.81 21 4.8 21H19.2C20.19 21 21 20.19 21 19.2V4.8C21 3.81 20.19 3 19.2 3ZM8.4 18.3H5.7V10.2H8.4V18.3ZM7.05 8.67C6.15 8.67 5.43 7.95 5.43 7.05C5.43 6.15 6.15 5.43 7.05 5.43C7.95 5.43 8.67 6.15 8.67 7.05C8.67 7.95 7.95 8.67 7.05 8.67ZM18.3 18.3H15.6V13.53C15.6 12.81 14.97 12.18 14.25 12.18C13.53 12.18 12.9 12.81 12.9 13.53V18.3H10.2V10.2H12.9V11.28C13.35 10.56 14.34 10.02 15.15 10.02C16.86 10.02 18.3 11.46 18.3 13.17V18.3Z'
                          fill='currentcolor'
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

